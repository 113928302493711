/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { SessionProvider } from 'next-auth/react'
import * as rainbowStyles from '@rainbow-me/rainbowkit/styles.css'

export const wrapRootElement = (props) => {
  return (
    <SessionProvider session={undefined} refetchInterval={240}>
      <CookiesProvider>{props.element}</CookiesProvider>
    </SessionProvider>
  )
}
